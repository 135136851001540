import React from 'react'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'

import './PaginationButton.scss'

function PaginationButton({ variant, ...props }) {
  const Icon = variant === 'prev' ? FiArrowLeftCircle : FiArrowRightCircle

  return (
    <button className='iliad__pagination-button' {...props}>
      <Icon />
    </button>
  )
}

export { PaginationButton }
