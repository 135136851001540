import { useState } from 'react'

const usePagination = (data, pageSize) => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(data.length / pageSize)

  const currentData = () => {
    // prevents responsive components from displaying empty pages when the window is resized larger
    if(currentPage > totalPages) {
      setCurrentPage((currentPage) => 1)
    }
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    return data.slice(start, end)
  }

  const next = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages))
  }

  const prev = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const goToPage = (page) => {
    const pageNumber = Math.max(1, page)
    setCurrentPage((currentPage) => Math.min(pageNumber, totalPages))
  }

  return { next, prev, goToPage, currentData, currentPage, totalPages }
}

export default usePagination