import React from 'react'
import { graphql, useScrollRestoration } from 'gatsby'
import loadable from '@loadable/component'
import Seo from 'components/Seo'
import PageLayout from '../layouts/PageLayout'
import Section from 'components/Section/Section'
import { Calendar, strainsCalendar } from 'components/Calendar'
import { getSrc } from 'gatsby-plugin-image'
import { flattenStrain } from '../utils/normalize'

const StrainCollection = loadable(() =>
  import('../components/StrainCollection')
)

const ProductsPage = ({
  data: {
    allFile: { strainNodes },
    markdownRemark: {
      frontmatter: { meta },
    },
  },
  location: { pathname },
}) => {
  const allStrains = strainNodes.map((strain) => flattenStrain(strain))

  /* Filter out any strain data that isn't within the next 5 months,
  map the strain data to each month */
  const strainCalendar = buildStrainsCalendar(strainsCalendar).map((month) => {
    return {
      ...month,
      strains: month.strains.map((strain) =>
        allStrains.find(
          ({ name }) => name.toLowerCase() === strain.toLowerCase()
        )
      ),
    }
  })

  /* Only pass the first month into the strain collection append the More 
  strains card to the end of the first month this is due to how pagination 
  is handled - needs a fix. */
  const currentMonthStrains = [
    ...strainCalendar[0].strains,
    allStrains.find(({ name }) => name === 'More Strains'),
  ]

  const snapScrollRestoration = useScrollRestoration('snap--container')
  const imagePath = getSrc(meta.ogImage)

  return (
    <PageLayout pathname={pathname} scrollRestoration={snapScrollRestoration}>
      <Seo
        title={meta.title}
        description={meta.metaDescription}
        ogImage={imagePath}
        meta={meta}
        pathname={pathname}
      />
      <Section
        index='0'
        id={`page-section-0`}
        as='section'
        colorTheme='white'
        fullHeight
        pathname={pathname}>
        <StrainCollection strains={currentMonthStrains} />
      </Section>
      <Section
        index='1'
        id={`page-section-1`}
        as='section'
        colorTheme='white'
        fullHeight
        pathname={pathname}>
        <Calendar strainCalendar={strainCalendar} />
      </Section>
    </PageLayout>
  )
}

export default ProductsPage

export const query = graphql`
  query ProductsPageQuery {
    markdownRemark(frontmatter: { title: { eq: "products" } }) {
      id
      frontmatter {
        meta {
          title
          metaDescription
          ogImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "strains" } }) {
      strainNodes: nodes {
        id
        childMarkdownRemark {
          id
          fields {
            slug
          }
          frontmatter {
            name
            description
            isSoldOut
            displayOnSite
            availability
            photo {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

/**
 * Builds a strains calendar based on the current month
 * @param {Array} strainsCalendar - Array of objects representing the strains calendar
 * @returns {Array} - Array of objects representing the strains calendar
 */
function buildStrainsCalendar(strainsCalendar) {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const monthName = currentDate.toLocaleString('default', {
    month: 'long',
  })

  const startingIndex = strainsCalendar.findIndex(
    (month) => month.month === monthName && month.year === year.toString()
  )

  return strainsCalendar.slice(startingIndex, startingIndex + 6)
}
